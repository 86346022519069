<template>
  <div
    class="file-preview"
    :class="{ failed: isFailed, uploaded: isUploaded }"
  >
    <!--
    <div v-if="!isUploaded && !isFailed" class="loading">
      <BSpinner variant="light" />
    </div>
    <div v-if="isUploaded || isFailed" class="file-status">
      <FeatherIcon v-if="isUploaded" icon="CheckIcon" />
      <FeatherIcon v-if="isFailed" icon="AlertCircleIcon" />
    </div>
    -->

    <div class="file-action">
      <a
        v-if="false"
        :href="src"
        target="_blank"
        class="view-file btn btn-primary btn-sm"
      >
        <FeatherIcon icon="EyeIcon" />
      </a>

      <BButton
        :disabled="isDisabledMode"
        variant="danger"
        size="sm"
        @click="$emit('remove')"
      >
        <FeatherIcon icon="TrashIcon" />
      </BButton>
    </div>

    <img
      v-if="type === 'image'"
      :src="src"
      :alt="filename"
    >
    <div
      v-else
      class="other-file"
    >
      <FeatherIcon
        icon="FileTextIcon"
        size="64"
      />
    </div>
    <div class="text-center mt-1">
      <small class="text-center text-muted">{{ filename }}</small>
    </div>
  </div>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'

export default {
  name: 'FileUploadPreview',
  components: { BButton, BSpinner },
  props: {
    type: {
      type: String,
      default: 'image',
    },
    src: {
      type: [String, Object],
      default: () => null,
    },
    filename: {
      type: String,
      default: null,
    },
    isUploaded: {
      type: Boolean,
      default: false,
    },
    isFailed: {
      type: Boolean,
      default: false,
    },
    isDisabledMode: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.file-preview {
  width: 150px;
  height: 150px;
  border: 1px solid #ccc;
  position: relative;
  border-radius: 5px;
  padding: 2px;
  cursor: pointer;

  &.uploaded {
    border-color: #28c76f;

    .file-status {
      color: #28c76f;
    }
  }

  &.failed {
    border-color: #ea5455;

    .file-status {
      color: #ea5455;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .other-file {
    // position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 98;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 99;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .file-status {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 99;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    text-align: center;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .file-action {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    opacity: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    z-index: 99;

    .view-file {
      margin-right: 4px;
    }
  }

  &:hover {
    .file-action {
      display: flex;
      opacity: 1;
      transition: all 0.5s ease-in;
    }
  }
}
</style>
