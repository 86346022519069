<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h4>
        Advance Search
      </h4>
    </b-card-header>
    <b-card-body>
      <b-row class="mt-1">
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Main Title"
            label-cols-md="4"
          >
            <b-form-input
              v-model="value.main_title"
              class="w-100"
              placeholder="Enter Main title"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Sub Title"
            label-cols-md="4"
          >
            <b-form-input
              v-model="value.sub_title"
              class="w-100"
              placeholder="Enter Sub title"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Plan Owner"
            label-cols-md="4"
          >
            <b-form-input
              v-model="value['planOwner.name']"
              class="w-100"
              placeholder="Enter Plan Owner"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Plan Type"
            label-cols-md="4"
          >
            <b-form-input
              v-model="value['planType.name']"
              class="w-100"
              placeholder="Enter Plan Type"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Project Type"
            label-cols-md="4"
          >
            <b-form-input
              v-model="value['projectType.name']"
              class="w-100"
              placeholder="Enter Project Type"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Keyword(s)"
            label-cols-md="4"
          >
            <b-form-input
              v-model="value['keywords.name']"
              class="w-100"
              placeholder="Enter Keyword"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Plan No"
            label-cols-md="4"
          >
            <b-form-input
              v-model="value.plan_no"
              class="w-100"
              placeholder="Enter Plan No"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Contract No"
            label-cols-md="4"
          >
            <b-form-input
              v-model="value.contract_no"
              class="w-100"
              placeholder="Enter Contract No"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="State"
            label-cols-md="4"
          >
            <v-select
              v-model="value['state.name']"
              :options="stateOptions"
              class="w-100"
              label="stateConcatenated"
              :reduce="val => val.state"
              @option:selected="value['district.name'] = null"
              @input="(val) => $emit('update:filter.state', val)"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="District"
            label-cols-md="4"
          >
            <v-select
              v-model="value['district.name']"
              :options="value['state.name']
                ? districtOptions.filter(d => d.state_id == states.find(s => s.state === value['state.name']).id)
                : districtOptions"
              class="w-100"
              label="districtConcatenated"
              :reduce="val => val.district"
              @input="(val) => $emit('update:filter.district', val)"
            >
              <template #option="{ districtConcatenated }">
                {{ `${districtConcatenated}` }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          cols="12"
          md="auto"
          class="ml-md-auto pr-md-0"
        >
          <b-button
            class="w-100"
            variant="outline-primary"
            @click="onReset"
          >
            Reset
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="auto"
        >
          <b-button
            class="w-100 my-1 my-sm-1 my-md-0"
            variant="primary"
            @click="onSubmit"
          >
            Search
          </b-button>
        </b-col>
      </b-row>
      <b-row />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { get } from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import documentStoreModule from '@/views/eplan/documents/documentStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      defaultValue: {
        main_title: null,
        sub_title: null,
        'planOwner.name': null,
        'planType.name': null,
        'projectType.name': null,
        'keywords.name': null,
        plan_no: null,
        contract_no: null,
        state: null,
        district: null,
        'state.name': null,
        'district.name': null,
      },
      value: {},
      states: [],
      districts: [],
    }
  },
  computed: {
    stateOptions() {
      return this.states.map(s => ({
        ...s,
        stateConcatenated: `${s.code} - ${s.state}`,
      }))
    },
    districtOptions() {
      return this.districts.map(d => ({
        ...d,
        districtConcatenated: `${d.code} - ${d.district}`,
      }))
    },
  },
  created() {
    // Register module
    if (!store.hasModule('app-document')) store.registerModule('app-document', documentStoreModule)
  },
  // destroyed() {
  //   if (store.hasModule('app-document')) store.unregisterModule('app-document')
  // },
  mounted() {
    this.value = {
      ...this.defaultValue,
      ...this.filter,
    }

    store
      .dispatch('app-document/fetchDocumentMeta', {
        field: 'state_id;district_id',
      })
      .then(response => {
        this.states = get(response, 'data.payload.state_id')
        this.districts = get(response, 'data.payload.district_id')

        // load from route.query
        for (const key of Object.keys(this.value)) {
          this.value[key] = this.$route.query[key]
        }
        this.onSubmit()
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching documents' meta",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    onSubmit() {
      this.$emit('search', this.value)
    },
    onReset() {
      this.value = { ...this.defaultValue }
      this.$emit('search', {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
