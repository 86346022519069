<template>
  <div>
    <b-tabs
      v-model="tabIndex"
      vertical
      pills
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <b-tab
        active
      >
        <template #title>
          <FeatherIcon
            icon="ToggleLeftIcon"
            size="32"
            class="mr-2"
          />
          <span class="font-weight-bold">
            Upload Type
            <div style="margin-top:6px">
              <small class="text-muted">Selected: {{ uploadMultiple ? 'multiple' : 'single' }}</small>
            </div>
          </span>
        </template>

        <b-overlay :show="isSaving">
          <b-card class="mb-1">
            <b-row class="m-5">
              <b-col
                cols="12"
                md="6"
                class="text-center"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="upload-type-button"
                  :variant="uploadMultiple ? 'outline-secondary' : 'primary'"
                  @click="uploadMultiple = false; onNextTab(tabIndex)"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="48"
                  />
                  <div class="mt-1 mb-2 align-middle">
                    Single File Upload
                  </div>
                  <small class="text-muted">Upload single file with more details</small>
                </b-button>
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="text-center"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="upload-type-button"
                  :variant="uploadMultiple ? 'primary' : 'outline-secondary'"
                  @click="uploadMultiple = true; onNextTab(tabIndex)"
                >
                  <feather-icon
                    icon="FilePlusIcon"
                    size="48"
                  />
                  <div class="mt-1 mb-2 align-middle">
                    Upload Multiple Files
                  </div>
                  <small class="text-muted">Quick bulk upload multiple files</small>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-tab>

      <b-tab>
        <template #title>
          <FeatherIcon
            icon="FileIcon"
            size="32"
            class="mr-2"
          />
          <span class="font-weight-bold">
            Document Info
          </span>
        </template>

        <b-overlay :show="isSaving">
          <b-card class="mb-1 p-2">
            <validation-observer
              ref="documentFormTabInfo"
              slim
            >
              <b-row>
                <!-- main title -->
                <b-col
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Main title"
                    vid="form.main_title"
                  >
                    <b-form-group
                      label="Main Title"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <vue-autosuggest
                        id="main-title"
                        v-model="form.main_title"
                        :suggestions="filteredSuggestion.main_title"
                        :get-suggestion-value="(suggestion) => suggestion.item.main_title"
                        :limit="10"
                        :input-props="{
                          id:'autosuggest__input',
                          class:{ 'form-control': true, 'is-invalid': errors.length > 0 },
                          placeholder:'Enter or select document main title'
                        }"
                        :state="errors.length > 0 ? false : null"
                        @selected="onSelected('main_title', $event.item.main_title)"
                        @input="onSuggestInput('main_title', $event)"
                        @blur="form.main_title = form.main_title.toUpperCase()"
                      >
                        <template slot-scope="{suggestion}">
                          <span class="my-suggestion-item">{{ suggestion.item.main_title }}</span>
                        </template>
                      </vue-autosuggest>
                      <small
                        v-if="errors"
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- type of project -->
                <b-col
                  v-if="!uploadMultiple"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Type of Project"
                    vid="form.project_type"
                  >
                    <b-form-group
                      label="Type of Project"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <v-select
                        id="project-type"
                        v-model="form.project_type"
                        :options="suggestion.project_type.filter(t => !!t.is_active)"
                        label="project_type"
                        :reduce="option => option.project_type"
                        placeholder="Select or search Type of Project"
                        @option:selected="onSelected('project_type', $event, 'project_type')"
                      />
                      <!-- vue-autosuggest
                        id="project-type"
                        v-model="form.project_type"
                        :suggestions="filteredSuggestion.project_type"
                        :get-suggestion-value="(suggestion) => suggestion.item.project_type"
                        :limit="10"
                        :input-props="{id:'autosuggest__input',class:{ 'form-control': true, 'is-invalid': errors.length > 0 }, placeholder:'Enter or select document project type'}"
                        @selected="onSelected('project_type', $event.item, 'project_type')"
                        @input="onSuggestInput('project_type', $event)"
                      >
                        <template slot-scope="{suggestion}">
                          <span class="my-suggestion-item">{{ suggestion.item.project_type }}</span>
                        </template>
                      </vue-autosuggest -->
                      <small
                        v-if="errors"
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- type of plan -->
                <b-col
                  v-if="!uploadMultiple"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Type of Plan"
                    vid="form.plan_type"
                  >
                    <b-form-group
                      label="Type of Plan"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <v-select
                        id="plan-type"
                        v-model="form.plan_type"
                        :options="suggestion.plan_type.filter(p => !!p.is_active)"
                        label="plan_type"
                        :reduce="option => option.plan_type"
                        placeholder="Select or search Type of Plan"
                        @option:selected="onSelected('plan_type', $event, 'plan_type')"
                      />
                      <!-- vue-autosuggest
                        id="plan-type"
                        v-model="form.plan_type"
                        :suggestions="filteredSuggestion.plan_type"
                        :get-suggestion-value="(suggestion) => suggestion.item.plan_type"
                        :limit="10"
                        :input-props="{id:'autosuggest__input',class:{ 'form-control': true, 'is-invalid': errors.length > 0 }, placeholder:'Enter or select document plan type'}"
                        @selected="onSelected('plan_type', $event.item, 'plan_type')"
                        @input="onSuggestInput('plan_type', $event)"
                      >
                        <template slot-scope="{suggestion}">
                          <span class="my-suggestion-item">{{ suggestion.item.plan_type }}</span>
                        </template>
                      </vue-autosuggest -->
                      <small
                        v-if="errors"
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- plan owner -->
                <b-col
                  v-if="!uploadMultiple"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Plan Owner"
                    vid="form.plan_owner"
                  >
                    <b-form-group
                      label="Plan Owner"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <v-select
                        id="plan-owner"
                        v-model="form.plan_owner"
                        :options="suggestion.plan_owner"
                        label="plan_owner"
                        :reduce="option => option.plan_owner"
                        placeholder="Select or search Plan Owner"
                        :disabled="true"
                        @option:selected="onSelected('plan_owner', $event, 'plan_owner')"
                      />
                      <!-- vue-autosuggest
                        id="plan-owner"
                        v-model="form.plan_owner"
                        :suggestions="filteredSuggestion.plan_owner"
                        :get-suggestion-value="(suggestion) => suggestion.item.plan_owner"
                        :limit="10"
                        :input-props="{
                          id:'autosuggest__input',
                          class:{ 'form-control': true, 'is-invalid': errors.length > 0 },
                          placeholder:'Enter or select document plan type',
                          disabled: true,
                        }"
                        @selected="onSelected('plan_owner', $event.item, 'plan_owner')"
                        @input="onSuggestInput('plan_owner', $event)"
                      >
                        <template slot-scope="{suggestion}">
                          <span class="my-suggestion-item">{{ suggestion.item.plan_owner }}</span>
                        </template>
                      </vue-autosuggest -->
                      <small
                        v-if="errors"
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- state -->
                <b-col
                  v-if="!uploadMultiple"
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="State"
                    vid="form.state_id"
                  >
                    <b-form-group
                      label="State"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <v-select
                        id="state"
                        v-model="form.state_id"
                        label="state"
                        append-to-body
                        :options="states"
                        :reduce="state => state.id"
                        placeholder="Select State"
                        :state="errors.length > 0 ? false:null"
                        @option:selected="form.district_id = null"
                      />
                      <small
                        v-if="errors"
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- district -->
                <b-col
                  v-if="!uploadMultiple"
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="District"
                    vid="form.district_id"
                  >
                    <b-form-group
                      label="District"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <v-select
                        id="district"
                        v-model="form.district_id"
                        :disabled="!form.state_id"
                        label="district"
                        append-to-body
                        :options="districts.filter(district => district.state_id == form.state_id)"
                        :reduce="district => district.id"
                        placeholder="Select District"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small
                        v-if="errors"
                        class="text-danger"
                      >{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  v-if="!uploadMultiple"
                  cols="12"
                  class="p-0 m-0"
                >
                  <app-collapse type="default">
                    <app-collapse-item
                      title="Additional Information"
                      class="m-0 p-0"
                    >
                      <!-- sub title -->
                      <b-col
                        cols="12"
                        class="mt-2 mt-md-0 ml-mr-0"
                      >
                        <b-form-group label="Sub Title">
                          <vue-autosuggest
                            id="main-title"
                            v-model="form.sub_title"
                            :suggestions="filteredSuggestion.sub_title"
                            :get-suggestion-value="(suggestion) => suggestion.item.sub_title"
                            :limit="10"
                            :input-props="{
                              id:'autosuggest__input',
                              class:{ 'form-control': true },
                              placeholder:'Enter or select document sub title'
                            }"
                            @selected="onSelected('sub_title', $event.item.sub_title)"
                            @input="onSuggestInput('sub_title', $event)"
                            @blur="form.sub_title = form.sub_title.toUpperCase()"
                          >
                            <template slot-scope="{suggestion}">
                              <span class="my-suggestion-item">{{ suggestion.item.sub_title }}</span>
                            </template>
                          </vue-autosuggest>
                        </b-form-group>
                      </b-col>
                      <!-- keywords -->
                      <b-col cols="12">
                        <b-form-group label="Keywords">
                          <v-select
                            id="keywords"
                            v-model="form.keywords"
                            label="name"
                            append-to-body
                            multiple
                            taggable
                            push-tags
                            :options="keywords"
                            placeholder="Select/enter keyword(s)"
                            @search="onKeywordSearch"
                          />
                        </b-form-group>
                      </b-col>
                    </app-collapse-item>
                  </app-collapse>
                </b-col>

              </b-row>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-tab>

      <b-tab :disabled="!tabValidated.tabInfo">
        <template #title>
          <FeatherIcon
            icon="FilePlusIcon"
            size="32"
            class="mr-2"
          />
          <span class="font-weight-bold">
            File(s) Upload
          </span>
        </template>

        <b-overlay :show="isSaving">
          <b-card class="mb-1">
            <b-row class="mt-2 mb-2">
              <b-col>
                <BaseFileUpload
                  :key="triggerKey"
                  :multiple="uploadMultiple"
                  :max-files="uploadMultiple ? 5 : 1"
                  :uploaded-files-props="form.files"
                  @input="onAttachmentsChange"
                />
                <div
                  v-if="isUploadAttempted && form.files.length < 1"
                  class="text-center mt-1"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    class="text-danger mr-1"
                  />
                  <small class="text-danger">Error! at least 1 file required!</small>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-tab>

      <!-- buttons -->
      <b-card class="mt-0">
        <div class="text-right">
          <b-button
            v-if="tabIndex > 0"
            variant="outline-primary"
            class="mr-1"
            :disabled="isSaving"
            @click="tabIndex--"
          >
            Previous
          </b-button>
          <b-button
            variant="outline-primary"
            class="mr-1"
            :disabled="isSaving"
            :to="{ name: 'document-list'}"
          >
            Cancel
          </b-button>
          <b-button
            v-if="tabIndex != 2"
            variant="primary"
            :disabled="isSaving"
            @click="onNextTab(tabIndex)"
          >
            Next
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-1"
            />
          </b-button>
          <b-button
            v-else
            variant="primary"
            :disabled="isSaving"
            @click="onNextTab(tabIndex)"
          >
            <b-spinner
              v-if="isSaving"
              small
              type="grow"
            />
            <feather-icon
              v-else
              icon="SaveIcon"
            />
            {{ isSaving ? 'saving...' : 'Upload!' }}
          </b-button>
        </div>
      </b-card>
    </b-tabs>

  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, email } from '@validations'
import { nextTick, onUnmounted, ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
  BTab,
  BTabs,
  BSpinner,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { debounce, get } from 'lodash'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import BaseFileUpload from './-components/BaseFileUpload.vue'
import documentStoreModule from '../documentStoreModule'

export default {
  directives: {
    Ripple,
  },
  components: {
    VueAutosuggest,
    BaseFileUpload,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    BCol,
    BRow,
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,
    BOverlay,
    vSelect,
    AppCollapse,
    AppCollapseItem,
  },
  setup() {
    const { router } = useRouter()

    const DOCUMENT_STORE_MODULE_NAME = 'app-document'

    // Register module
    if (!store.hasModule(DOCUMENT_STORE_MODULE_NAME)) store.registerModule(DOCUMENT_STORE_MODULE_NAME, documentStoreModule)

    const toast = useToast()

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCUMENT_STORE_MODULE_NAME)) store.unregisterModule(DOCUMENT_STORE_MODULE_NAME)
    })

    const userData = getUserData()

    const documentFormTabInfo = ref(null)
    const tabValidated = ref({
      tabInfo: false,
      tabFile: false,
    })
    const keywords = ref([])
    const form = ref({
      files: [],
    })
    const isSaving = ref(false)
    const isUploadAttempted = ref(false)
    const uploadMultiple = ref(false)
    const selectedValue = ref({
      plan_owner: null,
    })

    const tabIndex = ref(0)
    const triggerKey = ref(0)
    const suggestion = ref({
      main_title: [],
      sub_title: [],
      project_type: [],
      plan_type: [],
      plan_owner: [],
    })
    const filteredSuggestion = ref({
      main_title: [],
      sub_title: [],
      project_type: [],
      plan_type: [],
      plan_owner: [],
    })
    const states = ref([])
    const districts = ref([])

    store
      .dispatch('app-document/fetchDocumentMeta', {
        field: 'main_title;sub_title;project_type_id;plan_type_id;plan_owner_id;state_id;district_id',
      })
      .then(response => {
        suggestion.value.main_title = get(response, 'data.payload.main_title')
        suggestion.value.sub_title = get(response, 'data.payload.sub_title')
        suggestion.value.project_type = get(response, 'data.payload.project_type_id')
        suggestion.value.plan_type = get(response, 'data.payload.plan_type_id')
        suggestion.value.plan_owner = get(response, 'data.payload.plan_owner_id')
        states.value = get(response, 'data.payload.state_id')
        districts.value = get(response, 'data.payload.district_id')

        // pre-select plan_owner
        if (userData.plan_owner_id) {
          nextTick(() => {
            // console.log(userData)
            selectedValue.value.plan_owner = suggestion.value.plan_owner.find(p => p.id === userData.plan_owner_id)
            // console.log(selectedValue.value.plan_owner)
            form.value.plan_owner = get(selectedValue.value, 'plan_owner.plan_owner')
            // console.log(form.value.plan_owner)
          })
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching documents' meta",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    const onAttachmentsChange = files => {
      form.value.files = files
    }

    const onSuggestInput = (key, text) => {
      form.value[key] = text

      selectedValue.value[key] = {}
      selectedValue.value[key].id = null
      selectedValue.value[key][key] = text

      if (text === '' || text === undefined) {
        return
      }

      const filtered = suggestion.value[key].filter(data => data[key].toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, 10)
      nextTick(() => {
        filteredSuggestion.value[key] = [{ data: filtered }]
      })
    }

    const onSelected = (field, value, key = null) => {
      console.log(value)
      if (key) {
        selectedValue.value[field] = value
      }
      form.value[field] = key ? value[key] : value
    }

    const buildFormData = (formData, data, parentKey) => {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
          buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
      } else {
        const value = data == null ? '' : data
        formData.append(parentKey, value)
      }
    }

    const jsonToFormData = data => {
      const formData = new FormData()
      buildFormData(formData, data)
      return formData
    }

    const upload = () => {
      isSaving.value = true

      // api upload
      const formData = jsonToFormData(form.value)
      // replace with selectedValue
      Object.keys(selectedValue.value).forEach(key => {
        // TODO: main_title field is currently string
        if (key != 'main_title' && key != 'sub_title') {
          formData.delete(key)
          buildFormData(formData, selectedValue.value[key], key)
        }
      })
      // misc
      formData.append('type', uploadMultiple ? 'bulk' : 'single')

      store
        .dispatch('app-document/uploadDocument', formData)
        .then(() => {
          isSaving.value = false

          // redirect
          router.push({ name: 'document-list' })

          // show toast
          nextTick(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'File has been uploaded',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          isSaving.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error saving document',
              text: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const onNextTab = async tab => {
      // 0: upload type, 1: Document Info, 2: File Upload

      let canProceed = true
      isUploadAttempted.value = false

      if (tab == 1) {
        canProceed = false
        await documentFormTabInfo.value.validate().then(success => {
          if (success) {
            tabValidated.value.tabInfo = true
            canProceed = true
          }
        }).catch(() => {
          tabValidated.value.tabInfo = false
          canProceed = false
        })
      }

      if (tab == 2) {
        canProceed = false
        isUploadAttempted.value = true
        if (form.value.files.length) {
          canProceed = true
          upload()
        }
      }

      if (canProceed) {
        nextTick(() => {
          tabIndex.value += 1
        })
      }
    }

    const onKeywordSearch = debounce((search, loading) => {
      if (!search) return
      loading(true)
      store.dispatch('app-document/searchKeywordTags', search)
        .then(response => {
          console.log(response.data.payload)
          keywords.value = response.data.payload
        })
        .catch(() => {
          keywords.value = []
        })
        .finally(() => {
          loading(false)
        })
    },
    200)

    return {
      documentFormTabInfo,
      tabValidated,
      keywords,
      form,
      isSaving,
      isUploadAttempted,
      uploadMultiple,
      selectedValue,
      tabIndex,
      triggerKey,
      suggestion,
      filteredSuggestion,
      states,
      districts,
      onAttachmentsChange,
      onSuggestInput,
      onSelected,
      onNextTab,
      onKeywordSearch,
      required,
      email,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.upload-type-button {
  width: 240px;
  height: 200px;
  padding: 10px;
}
</style>
