<template>
  <iframe
    width="100%"
    height="100%"
    :src="`${getFilePath}`"
  />
</template>

<script>
export default {
  name: 'PDFJSViewer',
  props: {
    fileName: {
      type: String,
      default: '',
      required: true,
    },
    path: {
      type: String,
      default: '/lib/pdfjs/web/viewer.html',
      required: false,
    },
  },
  computed: {
    getFilePath() {
      if (this.fileName != '') {
        return `${this.path}?file=${this.fileName}`
      }
      return this.path
    },
  },
}
</script>

<style scoped>
div {
  width: 50%;
  height: 79vh;
  min-width: 400px;
}
</style>
