<template>
  <section class="document-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="!hasFile"
    >
      <h4 class="alert-heading">
        Error fetching document data
      </h4>
      <div class="alert-body">
        No document found with this document id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'document-list'}"
        >
          Document List
        </b-link>
        for other documents.
      </div>
    </b-alert>

    <b-row>
      <b-col
        lg="9"
        md="8"
        sm="12"
        class="p-1"
      >
        <div
          v-if="hasFile"
          class="p-0"
          style="min-height:75vh"
        >
          <!-- vue-pdf-embed source="/sds.pdf" / -->
          <pdf-js-viewer
            :file-name="getFileUrl(documentData.hashid)"
            style="min-height: 75vh"
          />

          <div class="p-50">
            <small class="text-muted">
              <span class="font-weight-bold">Disclaimer: </span>
              <span>
                The information contained in this document is confidential, privileged and only for the information
                of the intended audience and may not be used, published or redistributed without the prior consent of
                Jabatan Pengairan dan Saliran (JPS).
              </span>
            </small>
          </div>
        </div>
      </b-col>
      <b-col
        lg="3"
        md="4"
        sm="12"
        class="p-1"
      >
        <b-card no-body>
          <b-card-body>
            <b-media
              no-body
              class="mb-2"
            >
              <b-media-aside class="mr-1">
                <b-img
                  src="/images/file-types/pdf.png"
                  width="84"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <b-card-text class="font-weight-bolder mb-0 text-break">
                  {{ get(documentData, 'files.original.basename') }}
                  <div class="font-small-3 mb-0 text-muted">
                    Size: {{ get(documentData, 'files.original.size', 0) | prettyBytes }}
                  </div>
                </b-card-text>
              </b-media-body>
            </b-media>
            <div class="text-center">
              <b-button
                variant="outline-primary"
                @click="onClose"
              >
                Close Preview
              </b-button>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  ref, onUnmounted, computed,
} from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import {
  BRow, BCol, BCard, BCardBody, BButton, BAlert, BLink, VBToggle, BMedia, BMediaBody, BMediaAside, BCardText,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import PdfJsViewer from '@/components/PDFJSViewer.vue'
import { get } from 'lodash'
import documentStoreModule from '../documentStoreModule'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BAlert,
    BLink,
    BMedia,
    BMediaBody,
    BMediaAside,
    BCardText,
    BImg,

    PdfJsViewer,
  },
  props: {
    from: {
      type: Object,
      default: null,
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from
    })
  },
  setup(props) {
    const documentData = ref({
      files: { original: {}, converted: {} },
    })

    const DOCUMENT_STORE_MODULE_NAME = 'app-document'
    // const { isVerticalMenuCollapsed } = store.state.verticalMenu
    const menuHidden = store.state.appConfig.layout.menu.hidden

    // Register module
    if (!store.hasModule(DOCUMENT_STORE_MODULE_NAME)) store.registerModule(DOCUMENT_STORE_MODULE_NAME, documentStoreModule)
    // store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
    store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DOCUMENT_STORE_MODULE_NAME)) store.unregisterModule(DOCUMENT_STORE_MODULE_NAME)
      // store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', isVerticalMenuCollapsed)
      store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', menuHidden)
    })

    const { route, router } = useRouter()

    const hasFile = computed(() => !!documentData.value.files && !!documentData.value.files.converted)

    store.dispatch('app-document/fetchDocument', { hashid: router.currentRoute.params.hashid })
      .then(response => {
        documentData.value = response.data.payload
      })
      .catch(error => {
        if (error.response.status === 404) {
          documentData.value = undefined
        }
      })

    const getFileUrl = hashid => `${process.env.VUE_APP_BASE_API_URL}/document/file/${hashid}/${router.currentRoute.params.preview}`

    const onClose = () => {
      // close current tab
      window.open('', '_self').close()

      // router.push(route.value.query.from ?? { name: 'document-list' })
      if (props.from) {
        router.replace(props.from)
      } else {
        router.go(-2)
      }
    }

    return {
      get,
      route,
      router,
      documentData,
      PdfJsViewer,
      hasFile,
      getFileUrl,
      onClose,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">

</style>
