<template>
  <b-card
    border-variant="secondary"
    class="mt-0 mb-0 shadow"
    :title="`Document Detail: #${ row.item.id }`"
  >
    <b-card-text>
      <b-row class="mb-2">
        <b-col
          :cols="get(row.item, 'files.original.mimetype') ? 8 : 12"
        >
          <b-row>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Main Title : </strong>{{ row.item.main_title }}
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Sub Title: </strong>
              <span v-html="formatData(row.item.sub_title)" />
            </b-col>
            <b-col
              class="mb-1"
              md="4"
            >
              <strong>Type of Plan: </strong>
              <span v-html="formatData(row.item.plan_type.name)" />
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Project Type: </strong>
              <span v-html="formatData(row.item.project_type.name)" />
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Detail Project Type: </strong>
              <span v-html="formatData(row.item.project_type_detail.name)" />
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Plan Owner: </strong>
              <span v-html="formatData(row.item.plan_owner.name)" />
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>State: </strong>
              <span v-html="formatData(row.item.state.name)" />
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>District: </strong>
              <span v-html="formatData(row.item.district.name)" />
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Record Creator: </strong>
              <span v-html="formatData(row.item.user.name)" />
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Designer: </strong>
              <span v-html="formatData(row.item.designer)" />
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Contract No.: </strong>
              <span v-html="formatData(row.item.contract_no)" />
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Plan #: </strong>
              <span v-html="formatData(row.item.plan_no)" />
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Year Key-in: </strong>
              <span v-html="formatData(row.item.year_key_in)" />
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Source: </strong>
              <span v-html="formatData(row.item.source)" />
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Status: </strong>
              <span v-html="formatData(capitalize(row.item.status))" />
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Plan Created Date: </strong>
              <span v-html="formatData(row.item.plan_created_date)" />
            </b-col>
            <b-col
              md="12"
              class="mb-1"
            >
              <strong>Keywords: </strong>
              <span v-html="mapKeywords(row.item.keywords)" />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="get(row.item, 'files.original.mimetype')"
          cols="4"
        >
          <b-media
            no-body
            class="mb-2"
          >
            <b-media-aside class="mr-1">
              <b-img
                :src="resolveFileIcon(get(row.item, 'files.original.mimetype'))"
                width="84"
              />
            </b-media-aside>
            <b-media-body class="my-auto">
              <b-card-text class="font-weight-bolder mb-0 text-break">
                {{ get(row.item, 'files.original.basename') }}
                <div class="font-small-3 mb-0 text-muted">
                  Type: {{ get(row.item, 'files.original.mimetype') }}
                </div>
                <div class="font-small-3 mb-0 text-muted">
                  Size: {{ get(row.item, 'files.original.size', 0) | prettyBytes }}
                </div>
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-text>
    <div class="text-right">
      <b-button-group>
        <b-button
          v-if="hasFile(row) && !hasFile(row, 'converted')"
          variant="outline-warning"
          size="sm"
          :disabled="isGeneratingPDF.includes(row.item.hashid)"
          @click="$emit('generate-pdf', row.item.hashid)"
        >
          <b-spinner
            v-if="isGeneratingPDF.includes(row.item.hashid)"
            class="mr-50"
            small
          />
          <feather-icon
            v-else
            icon="FileIcon"
            class="mr-50"
          />
          <span class="align-middle">Generate Preview</span>
        </b-button>
        <b-button
          variant="outline-primary"
          size="sm"
          :disabled="!hasFile(row, 'converted')"
          :to="{ name: 'document-preview', params: { hashid: row.item.hashid } }"
          target="_blank"
        >
          <feather-icon
            icon="FileIcon"
            class="mr-50"
          />
          <span class="align-middle">Preview</span>
        </b-button>
        <b-dropdown
          right
          variant="outline-primary"
          size="sm"
          :disabled="!hasFile(row) && !hasFile(row, 'converted')"
        >
          <template #button-content>
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
            />
            <span class="align-middle">Download</span>
          </template>
          <b-dropdown-item
            :disabled="!hasFile(row) || !canDownload(row)"
            @click="getFile(row.item, 0)"
          >
            Original
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="!hasFile(row, 'converted')"
            @click="getFile(row.item, 1)"
          >
            PDF
          </b-dropdown-item>
        </b-dropdown>
        <b-button
          v-if="$ability.can('edit', 'document')"
          variant="outline-primary"
          size="sm"
          :to="{ name: 'document-edit', params: { id: row.item.hashid } }"
        >
          <feather-icon
            icon="Edit3Icon"
            class="mr-50"
          />
          <span class="align-middle">Edit</span>
        </b-button>
        <b-button
          variant="outline-danger"
          size="sm"
          @click="row.toggleDetails"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span class="align-middle">Close</span>
        </b-button>
      </b-button-group>
    </div>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BButton, BButtonGroup, BDropdown, BDropdownItem, BSpinner,
  BMedia, BMediaAside, BMediaBody, BImg,
} from 'bootstrap-vue'
import { get, capitalize } from 'lodash'
import store from '@/store'
import documentStoreModule from '@/views/eplan/documents/documentStoreModule'
import { httpGetFile } from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'

export default {
  name: 'DocumentListRowDetail',
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
  },
  props: {
    row: {
      type: Object,
      default: () => {},
      required: true,
    },
    isGeneratingPDF: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      capitalize,
    }
  },
  computed: {
    user() {
      return getUserData()
    },
  },
  created() {
    // Register module
    if (!store.hasModule('app-document')) store.registerModule('app-document', documentStoreModule)
  },
  methods: {
    get,
    hasFile(row, type = 'original') {
      return !!row.item.files
          && (type === 'original' ? !!row.item.files.original : !!row.item.files.converted)
    },
    canDownload(row) {
      return this.$ability.can('download', 'document') && row.item.plan_owner_id == this.user.plan_owner_id
    },
    formatData(data, emptyValue = null) {
      return data ?? emptyValue ?? '<span class="badge badge-light-secondary">NULL</span>'
    },
    mapKeywords(keywords) {
      if (keywords.length < 1) return this.formatData(null)
      return keywords.map(keyword => `<span class="badge badge-light-primary text-md-center">${keyword.name}</span>`).join(' ')
    },
    getFile(item, preview = 0) {
      store
        .dispatch('app-document/getFile', { hashid: item.hashid, preview })
        .then(response => {
          // httpGetFile({
          //   blob: response.data,
          //   fileType: item.files[preview ? 'converted' : 'original'].mimetype,
          //   fileName: item.files[preview ? 'converted' : 'original'].basename,
          // })
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', item.files[preview ? 'converted' : 'original'].basename)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error exporting documents',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally()
    },
    resolveFileIcon(mimetype) {
      switch (mimetype) {
        case 'application/pdf':
          return '/images/file-types/pdf.png'
        case 'image/vnd.dwg':
          return '/images/file-types/dwg.png'
        default:
          return '/images/file-types/document.png'
      }
    },
  },
}
</script>
