<template>
  <div>
    <documents-list-filters
      v-if="useAdvanceSearch"
      :filter="searchAdvance"
      @search="onSearch"
    />

    <b-card no-body>
      <b-card-body>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="8"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
              <v-select
                v-model="statusFilter"
                :options="statusOptions"
                class="document-filter-select mr-1"
                placeholder="All Status"
                multiple
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>

              <b-button
                v-if="$ability.can('add', 'document')"
                variant="primary"
                :to="{ name: 'document-add'}"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                Add
              </b-button>
              <b-button
                v-if="$ability.can('export', 'document')"
                variant="primary"
                class="ml-50"
                :disabled="isExporting"
                @click="exportDocuments()"
              >
                <b-spinner
                  v-if="isExporting"
                  small
                  type="grow"
                />
                <feather-icon
                  v-else
                  icon="UploadIcon"
                  class="mr-50"
                />
                {{ isExporting ? 'exporting...' : 'Export' }}
              </b-button>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="4"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-if="!useAdvanceSearch"
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-form-checkbox
                  v-model="useAdvanceSearch"
                  class="d-inline-block"
                >
                  Advance Search
                </b-form-checkbox>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card-body>

      <b-table
        ref="refDocumentListTable"
        :small="true"
        :striped="false"
        :bordered="false"
        :borderless="false"
        :hover="true"
        :sticky-header="true"
        :no-border-collapse="true"
        :items="fetchDocuments"
        :tbody-tr-class="rowClass"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="documents-table position-relative"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner />
            loading...
          </div>
        </template>
        <template #head(actions)>
          <feather-icon
            icon="MenuIcon"
            class="mx-auto"
          />
        </template>
        <template #row-details="row">
          <DocumentListRowDetail
            :row="row"
            :is-generating-p-d-f="isGeneratingPDF"
            @generate-pdf="generatePDFPreview"
          />
        </template>

        <template #cell(id)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`status-row-${data.item.id}`"
              :icon="resolveDocumentStatusVariantAndIcon(data.item.status).icon"
              size="18"
              class="mr-50"
              :class="`text-${resolveDocumentStatusVariantAndIcon(data.item.status).variant}`"
              @click="data.toggleDetails"
            />
            <feather-icon
              :id="`toggle-row-${data.item.id}`"
              :icon="data.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="18"
              @click="data.toggleDetails"
            />
            <b-tooltip
              :target="`status-row-${data.item.id}`"
              triggers="hover"
            >
              Status: {{ data.item.status }}
            </b-tooltip>
            <b-tooltip
              :target="`toggle-row-${data.item.id}`"
              triggers="hover"
            >
              {{ data.detailsShowing ? 'Hide' : 'Show' }} detail
            </b-tooltip>
          </div>
        </template>

        <template #cell(main_title)="data">
          <b-link
            v-if="$ability.can('edit', 'document')"
            :to="{ name: 'document-edit', params: { id: data.item.hashid } }"
          >
            {{ data.value }}
          </b-link>
          <span v-else>
            {{ data.value }}
          </span>
        </template>

        <template #cell(sub_title)="data">
          <b-badge
            v-if="!data.value"
            variant="light-secondary"
          >
            NULL
          </b-badge>
          <span v-else>{{ data.value }}</span>
        </template>

        <template #cell(plan_types|plan_types.name)="data">
          <b-badge
            v-if="!data.item.plan_type.name"
            variant="light-secondary"
          >
            NULL
          </b-badge>
          <span v-else>{{ data.item.plan_type.name }}</span>
        </template>

        <template #cell(plan_owners|plan_owners.name)="data">
          <b-badge
            v-if="!data.item.plan_owner.name"
            variant="light-secondary"
          >
            NULL
          </b-badge>
          <span v-else>{{ data.item.plan_owner.name }}</span>
        </template>

        <template #cell(project_types|project_types.name)="data">
          <b-badge
            v-if="!data.item.project_type.name"
            variant="light-secondary"
          >
            NULL
          </b-badge>
          <span v-else>{{ data.item.project_type.name }}</span>
        </template>

        <template #cell(states|states.name)="data">
          <b-badge
            v-if="!data.item.state.name"
            variant="light-secondary"
          >
            NULL
          </b-badge>
          <span
            v-else
            class="text-nowrap"
          >
            {{ data.item.state.name }}
          </span>
        </template>

        <template #cell(districts|districts.name)="data">
          <b-badge
            v-if="!data.item.district.name"
            variant="light-secondary"
          >
            NULL
          </b-badge>
          <span
            v-else
            class="text-nowrap"
          >
            {{ data.item.district.name }}
          </span>
        </template>

        <template #cell(actions)="data">

          <div class="text-nowrap">
            <b-link
              v-if="hasFile(data.item, 'converted') || hasFile(data.item, 'original')"
              :disabled="! hasFile(data.item, 'converted')"
              :to="{ name: 'document-preview', params: { hashid: data.item.hashid, preview: 1 } }"
              target="_blank"
            >
              <feather-icon
                :id="`document-preview-${data.item.hashid}`"
                :icon="hasFile(data.item, 'converted') ? 'EyeIcon' : 'EyeOffIcon'"
                size="16"
                class="mx-1"
              />
              <b-tooltip :target="`document-preview-${data.item.hashid}`">
                {{ hasFile(data.item, 'converted') ? 'Click to preview' : 'No file to preview' }}
              </b-tooltip>
            </b-link>
            <feather-icon
              v-else
              :id="`document-no-preview-${data.item.hashid}`"
              icon="AlertTriangleIcon"
              size="16"
              class="mx-1 text-danger"
            />
            <b-tooltip
              :target="`document-no-preview-${data.item.hashid}`"
              triggers="hover"
            >
              Error! Original file not found
            </b-tooltip>

            <!-- Dropdown -->
            <b-dropdown
              v-if="($ability.can('edit', 'document') || $ability.can('delete', 'document')) && canEditDelete(data.item)"
              variant="link"
              toggle-class="p-0"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item v-if="false">
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">Download</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$ability.can('edit', 'document') && canEditDelete(data.item)"
                :to="{ name: 'document-edit', params: { id: data.item.hashid } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">View / Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$ability.can('delete', 'document') && canEditDelete(data.item)"
                variant="danger"
                @click="deleteDocument(data.item.hashid)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalDocuments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import {
  ref, onUnmounted, onMounted, watch, nextTick,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { get, isEqual } from 'lodash'
import { httpGetFile } from '@/libs/axios'
import Swal from 'sweetalert2'
import DocumentListRowDetail from './-components/DocumentListRowDetail.vue'
import useDocumentList from './useDocumentList'

import documentStoreModule from '../documentStoreModule'
import DocumentsListFilters from './DocumentsListFilters.vue'

export default {
  components: {
    DocumentListRowDetail,
    DocumentsListFilters,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BFormCheckbox,
    BSpinner,

    vSelect,
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem('userData'))
    },
  },
  setup(props, context) {
    const toast = useToast()
    const router = context.root.$router
    const route = context.root.$route

    const DOCUMENT_STORE_MODULE_NAME = 'app-document'

    // Register module
    if (!store.hasModule(DOCUMENT_STORE_MODULE_NAME)) store.registerModule(DOCUMENT_STORE_MODULE_NAME, documentStoreModule)

    /*
    onMounted(() => {
      let thElm
      let startOffset

      Array.prototype.forEach.call(
        document.querySelectorAll('table th'),
        th => {
          th.style.position = 'relative'

          const grip = document.createElement('div')
          grip.innerHTML = '&nbsp;'
          grip.style.top = 0
          grip.style.right = 0
          grip.style.bottom = 0
          grip.style.width = '5px'
          grip.style.position = 'absolute'
          grip.style.cursor = 'col-resize'
          grip.addEventListener('mousedown', e => {
            thElm = th
            startOffset = th.offsetWidth - e.pageX
          })

          th.appendChild(grip)
        },
      )

      document.addEventListener('mousemove', e => {
        if (thElm) {
          thElm.style.width = `${startOffset + e.pageX}px`
        }
      })

      document.addEventListener('mouseup', () => {
        thElm = undefined
      })
    })
    */

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(DOCUMENT_STORE_MODULE_NAME)) store.unregisterModule(DOCUMENT_STORE_MODULE_NAME)
    // })

    const {
      getFetchPayload,
      fetchDocuments,
      statusOptions,
      tableColumns,
      perPage,
      currentPage,
      totalDocuments,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchAdvance,
      sortBy,
      isSortDirDesc,
      refDocumentListTable,

      statusFilter,
      useAdvanceSearch,

      refetchData,

      resolveDocumentStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useDocumentList()

    const onSearch = filter => {
      searchAdvance.value = filter
      refetchData()
    }

    const hasFile = (data, type = 'original') => !!data.files
        && (type === 'original' ? !!data.files.original : !!data.files.converted)

    const isGeneratingPDF = ref([])

    const generatePDFPreview = hashid => {
      isGeneratingPDF.value.push(hashid)

      store
        .dispatch('app-document/generatePDFPreview', hashid)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Document sent to generate PDF preview',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          // TODO: check document detail if the file converted successfully
          refetchData()
        })
        .finally(() => {
          isGeneratingPDF.value = isGeneratingPDF.value.filter(h => h !== hashid)
        })
    }

    const isExporting = ref(false)

    const exportDocuments = () => {
      // check if too many records selected to export
      if (totalDocuments.value > 5000) {
        // eslint-disable-next-line no-alert
        Swal.fire({
          title: 'Error! too many records selected for export',
          html: `There are ${totalDocuments.value} documents currently selected.<br /><br />Please try again with some filter and keep it below 5000 records.`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })

        return
      }

      isExporting.value = true
      store
        .dispatch('app-document/fetchDocuments', getFetchPayload(1))
        .then(response => {
          httpGetFile({
            blob: response.data,
            fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            fileName: 'eplan-documents-export.xlsx',
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error exporting documents',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          isExporting.value = false
        })
    }

    const deleteDocument = hashid => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-success ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('app-document/deleteDocument', hashid)
            .then(() => {
              refetchData()
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Document deleted',
                  icon: 'InfoIcon',
                  variant: 'success',
                },
              })
            })
            .catch(error => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error deleting documents',
                  text: error.response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }).finally()
        }
      })
    }

    const rowClass = (item, type) => {
      const colorClass = 'table-highlight'
      if (!item || type !== 'row') { return }

      // eslint-disable-next-line consistent-return
      if (item.status !== 'approved') { return colorClass }
    }

    const user = JSON.parse(localStorage.getItem('userData'))

    const canEditDelete = item => {
      if (['editor', 'administrator'].includes(user.role)) {
        return item.plan_owner_id === user.plan_owner_id
      }

      return false
    }

    const updateQueryParams = param => {
      const query = {
        ...param,
        ...route.query,
      }

      if (!isEqual(route.query, query)) {
        router.replace({ name: route.name, query }).catch(() => {})
      }
    }
    watch([useAdvanceSearch, searchAdvance, currentPage], () => {
      const params = {
        advancesearch: useAdvanceSearch.value ? 1 : 0,
        // page: currentPage.value,
        ...searchAdvance.value,
      }
      updateQueryParams(params)
    }, { deep: true })

    // load from route query string
    nextTick(() => {
      useAdvanceSearch.value = route.query.advancesearch == 1
    })

    return {
      fetchDocuments,
      exportDocuments,
      deleteDocument,
      rowClass,
      canEditDelete,
      isExporting,
      tableColumns,
      perPage,
      currentPage,
      totalDocuments,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchAdvance,
      sortBy,
      isSortDirDesc,
      refDocumentListTable,

      statusFilter,
      useAdvanceSearch,

      refetchData,

      statusOptions,

      avatarText,
      resolveDocumentStatusVariantAndIcon,
      resolveClientAvatarVariant,

      onSearch,
      hasFile,
      isGeneratingPDF,
      generatePDFPreview,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.documents-table {
  ::v-deep td {
    font-size: 0.9rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
  ::v-deep th {
    font-size: 0.9rem;
  }
}

.document-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    min-width: 70px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.table-highlight {
  background-color: #fff5f0;
}
.table-hover .table-highlight:hover {
  background-color: #fee0d2;
}

.b-table-sticky-header {
  max-height: 65vh;
}
</style>
