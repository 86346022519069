<template>
  <component :is="documentData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="documentData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching document data
      </h4>
      <div class="alert-body">
        No document found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'document-list'}"
        >
          Documents List
        </b-link>
        for other documents.
      </div>
    </b-alert>

    <b-tabs v-if="documentData">

      <!-- Tab: Information -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="24"
            class="mr-1"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <div class="mt-3">
          <document-edit-tab-information
            :document-data="documentData"
            :can-edit="canEdit"
          />
        </div>
      </b-tab>

      <!-- Tab: Statistics -->
      <b-tab v-if="false">
        <template #title>
          <feather-icon
            icon="PieChartIcon"
            size="24"
            class="mr-1"
          />
          <span class="d-none d-sm-inline">Statistics</span>
        </template>
        <document-edit-tab-statistics
          :user-data="documentData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Audit Logs -->
      <b-tab v-if="false">
        <template #title>
          <feather-icon
            icon="ClockIcon"
            size="24"
            class="mr-1"
          />
          <span class="d-none d-sm-inline">Audit Logs</span>
        </template>
        <document-edit-tab-social class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import documentStoreModule from '../documentStoreModule'
import DocumentEditTabStatistics from './DocumentEditTabStatistics.vue'
import DocumentEditTabInformation from './DocumentEditTabInformation.vue'
import DocumentEditTabSocial from './DocumentEditTabSocial.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    DocumentEditTabStatistics,
    DocumentEditTabInformation,
    DocumentEditTabSocial,
  },
  data() {
    return {
      documentData: {},
    }
  },
  computed: {
    canEdit() {
      const userData = getUserData()

      if (userData.role === 'systemadmin') {
        return true
      }

      if (userData.role === 'administrator') {
        // administrator can only edit their plan_owner_id
        return !userData.plan_owner_id || userData.plan_owner_id === this.documentData.plan_owner_id
      }

      if (userData.role === 'editor') {
        if (this.documentData.plan_owner_id !== userData.plan_owner_id) {
          return false
        }

        // editor can only edit their own data
        return this.documentData.user_id === userData.id && this.documentData.status != 'approved'
      }

      return false
    },
  },
  created() {
    // Register module
    if (!store.hasModule('app-document')) store.registerModule('app-document', documentStoreModule)
  },
  destroyed() {
    // Unregister store
    if (store.hasModule('app-document')) store.unregisterModule('app-document')
  },
  mounted() {
    store.dispatch('app-document/fetchDocument', { hashid: router.currentRoute.params.id, params: { with: 'keywords;user' } })
      .then(response => { this.documentData = response.data.payload })
      .catch(error => {
        if (error.response.status === 404) {
          this.documentData = {}
        }
      })
  },
}
</script>

<style>

</style>
