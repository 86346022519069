<template>
  <div id="app">
    <div class="container px-0">
      <form
        v-if="isInitial || isSaving"
        enctype="multipart/form-data"
        novalidate
      >
        <div
          :class="{ 'custom-disabled-color': isDisabledMode || files.length >= maxFiles }"
          class="d-flex align-items-center justify-content-center dropbox"
        >
          <input
            type="file"
            :multiple="multiple"
            :name="uploadFieldName"
            :disabled="isDisabledMode || isSaving || files.length >= maxFiles"
            accept=".jpg, .jpeg, .png, .bmp, .svg, .eps, .tif, .pdf, .dwg, .doc, .docx, .xls, .xlsx"
            class="input-file"
            @change="onInputFileChange"
          >
          <p
            v-if="isInitial"
            class="mt-1"
          >
            <strong>Click or drop file(s) here to upload</strong>
            <br><br>
            <ul class="text-left">
              <li><small>Accepted file types: images, pdf, office documents, CAD drawing</small></li>
              <li><small>Maximum file size {{ maxFileSize }} MB</small></li>
              <li><small>Maximum number of files: {{ maxFiles }}</small></li>
            </ul>
          </p>
          <p
            v-if="isSaving"
            class="mt-1"
          >
            {{ 'common.input.file_upload.uploading_files' }}
          </p>
        </div>
      </form>

      <ul
        v-if="files.length > 0"
        class="list-unstyled d-flex flex-row flex-nowrap py-2 mb-5"
      >
        <li
          v-for="(file, index) in files"
          :key="`attachment-${index}`"
          class="attachment-list"
        >
          <FileUploadPreview
            :type="file.type"
            :src="file.src"
            :filename="file.name"
            :is-uploaded="file.uploaded"
            :is-failed="file.failed"
            :is-disabled-mode="isDisabledMode"
            @remove="() => deleteFile(file, index)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import FileUploadPreview from './FileUploadPreview.vue'

export default {
  name: 'BaseFileUpload',
  components: {
    FileUploadPreview,
  },
  props: {
    isDisabledMode: {
      type: Boolean,
      default: false,
    },
    uploadedFilesProps: {
      type: Array,
      default: () => [],
    },
    uploadApi: {
      type: String,
      default: null,
      required: false,
    },
    deleteApi: {
      type: String,
      default: null,
      required: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxFiles: {
      type: Number,
      default: 5,
    },
    maxFileSize: {
      type: Number,
      default: 20, // in MB format
    },
  },
  data() {
    return {
      uploadedFiles: this.uploadedFilesProps,
      uploadError: null,
      currentStatus: 'STATUS_INITIAL',
      uploadFieldName: 'photos',
      files: [],
    }
  },
  computed: {
    isInitial() {
      return this.currentStatus === 'STATUS_INITIAL'
    },
    isSaving() {
      return this.currentStatus === 'STATUS_SAVING'
    },
    isSuccess() {
      return this.currentStatus === 'STATUS_SUCCESS'
    },
    isFailed() {
      return this.currentStatus === 'STATUS_FAILED'
    },
  },

  watch: {
    uploadedFiles: {
      deep: true,
      handler(files) {
        this.$emit('upload', files)
      },
    },
    files: {
      deep: true,
      handler(files) {
        this.$emit('input', files)
      },
    },
  },
  mounted() {
    if (this.uploadedFilesProps && this.uploadedFilesProps.length > 0) {
      this.currentStatus = 'STATUS_INITIAL'
      this.files = this.uploadedFilesProps.map(elem => {
        const fileExtension = this.getFileExtension(elem)
        return {
          src: elem,
          uploaded: true,
          type: fileExtension === 'pdf' ? 'pdf' : 'image',
        }
      })
    } else {
      this.reset()
    }
  },
  methods: {
    getFileExtension(filename) {
      return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename
    },
    reset() {
      // reset form to initial state
      this.currentStatus = 'STATUS_INITIAL'
      this.uploadedFiles = []
      this.uploadError = null
    },
    async uploadFile(file, fileIndex) {
      const bytesToMb = bytes => (bytes / (1024 * 1024)).toFixed(2)

      // if file size more than 2Mb show warning
      const fileSize = bytesToMb(file.size)
      if (fileSize > this.maxFileSize) {
        // show warning
        this.$bvToast.toast('Selected file size is too big', {
          title: `failed_upload_file ${file.name}`,
          variant: 'warning',
        })
        // remove from upload list
        this.files.splice(fileIndex, 1)
        this.uploadedFiles.splice(fileIndex, 1)
        return
      }

      const formData = new FormData()
      formData.append('file', file)
      formData.append('path', 'uploaded')

      try {
        const response = await store.dispatch(
          this.uploadApi,
          formData,
        )

        this.$bvToast.toast(response.message, {
          title: 'common.success',
          variant: 'success',
        })

        this.uploadedFiles.push(response.attributes.filename)
        this.files = this.files.map((uploadedFile, index) => {
          if (index === fileIndex) {
            return {
              ...uploadedFile,
              uploaded_name: response.attributes.filename,
              uploaded: true,
            }
          }

          return uploadedFile
        })
      } catch (error) {
        this.files = this.files.map((uploadedFile, index) => {
          if (index === fileIndex) {
            return {
              ...uploadedFile,
              failed: true,
            }
          }

          return uploadedFile
        })

        this.$bvToast.toast(error.message, {
          title: `error.failed_upload_file ${file.name}`,
          variant: 'error',
        })
      }
    },

    async deleteFile(file, fileIndex) {
      let deleted = false
      if (file.uploaded && file.src) {
        try {
          await store.dispatch(
            this.deleteApi,
            file.src,
          )

          this.uploadedFiles = this.uploadedFiles.filter(
            filename => filename !== file.src,
          )
          deleted = true
        } catch (error) {
          this.$bvToast.toast(error, {
            variant: 'danger',
            title: 'common.error',
          })
        }
      } else {
        deleted = true
      }

      if (deleted) {
        this.files = this.files.filter((deletedFile, index) => index !== fileIndex)
      }
    },

    async onInputFileChange(evt) {
      const { files } = evt.target
      if (files.length === 0) return

      // check size
      const bytesToMb = bytes => (bytes / (1024 * 1024)).toFixed(2)
      const filesArray = []
      Array.from(files).forEach(file => {
        // if file size more than 20Mb show warning
        const fileSize = bytesToMb(file.size)
        if (fileSize > this.maxFileSize) {
          // show warning
          this.$bvToast.toast(`File size exceed maximum file size allowed: ${this.maxFileSize} MB`, {
            title: `Error! ${file.name}`,
            variant: 'warning',
          })
          return
          // remove from upload list
          // filesArray.splice(fileIndex, 1)
        }
        filesArray.push(file)
      })

      // add to files array
      this.files = [
        ...this.files,
        ...filesArray
          .slice(0, this.maxFiles - this.files.length)
          .map(file => ({
            original: file,
            name: file.name,
            type: file.type.split('/')[0],
            src: URL.createObjectURL(file),
          })),
      ]

      // upload if API provided only
      if (this.uploadApi) {
        filesArray.map((file, index) => this.uploadFile(file, this.files.length - files.length + index))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-disabled-color {
  background: #efefef;
  color: #6e6b7b;
  pointer-events: none;
}

.dropbox {
  outline: 1px dashed #65a7db; /* the dash box */
  outline-offset: -2px;
  color: #65a7db;
  padding: 10px 10px;
  min-height: 150px; /* minimum height */
  max-height: 150px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.1em;
  text-align: center;
  //padding: 50px 0;
}
.attachment-list {
  margin-right: 8px;
}
.attachment-list img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>
