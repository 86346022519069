<template>
  <validation-observer
    ref="documentFormTabInfo"
    slim
  >
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <!-- Header: Document Info -->
        <div class="d-flex mb-2">
          <feather-icon
            icon="FileTextIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Document Information
          </h4>
        </div>

        <!-- Fields: Document Info -->
        <b-row>
          <!-- main title -->
          <b-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Main title"
              vid="form.main_title"
            >
              <b-form-group
                label="Main Title"
                :class="errors.length > 0 ? 'is-invalid' : null"
                label-class="field-required"
              >
                <vue-autosuggest
                  id="main-title"
                  v-model="form.main_title"
                  :suggestions="filteredSuggestion.main_title"
                  :get-suggestion-value="(suggestion) => suggestion.item.main_title"
                  :limit="10"
                  :input-props="{
                    id:'autosuggest__input',
                    class:{ 'form-control': true, 'is-invalid': errors.length > 0 },
                    placeholder:'Enter or select document main title',
                    disabled: !canEdit,
                  }"
                  :state="errors.length > 0 ? false : null"
                  @selected="onSelected('main_title', $event.item.main_title)"
                  @input="onSuggestInput('main_title', $event)"
                >
                  <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{ suggestion.item.main_title }}</span>
                  </template>
                </vue-autosuggest>
                <small
                  v-if="errors"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- sub title -->
          <b-col cols="12">
            <b-form-group
              label="Sub Title"
            >
              <vue-autosuggest
                id="sub-title"
                v-model="form.sub_title"
                :suggestions="filteredSuggestion.sub_title"
                :get-suggestion-value="(suggestion) => suggestion.item.sub_title"
                :limit="10"
                :input-props="{
                  id:'autosuggest__input',
                  class:{ 'form-control': true },
                  placeholder:'Enter or select document sub title',
                  disabled: !canEdit,
                }"
                @selected="onSelected('sub_title', $event.item.sub_title)"
                @input="onSuggestInput('sub_title', $event)"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.sub_title }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>

          <!-- type of project -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Type of Project"
              vid="form.project_type"
            >
              <b-form-group
                label="Type of Project"
                :class="errors.length > 0 ? 'is-invalid' : null"
                label-class="field-required"
              >
                <v-select
                  id="project-type"
                  v-model="form.project_type"
                  :options="suggestion.project_type"
                  label="project_type"
                  :reduce="option => option.project_type"
                  placeholder="Select or search Type of Project"
                  :disabled="!canEdit"
                  @option:selected="onSelected('project_type', $event, 'project_type')"
                />
                <!-- vue-autosuggest
                  id="project-type"
                  v-model="form.project_type"
                  :suggestions="filteredSuggestion.project_type"
                  :get-suggestion-value="(suggestion) => suggestion.item.project_type"
                  :limit="10"
                  :input-props="{
                    id:'autosuggest__input',
                    class:{ 'form-control': true, 'is-invalid': errors.length > 0 },
                    placeholder:'Enter or select document project type',
                    disabled: !canEdit,
                  }"
                  @selected="onSelected('project_type', $event.item, 'project_type')"
                  @input="onSuggestInput('project_type', $event)"
                >
                  <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{ suggestion.item.project_type }}</span>
                  </template>
                </vue-autosuggest -->
                <small
                  v-if="errors"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- type of plan -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Type of Plan"
              vid="form.plan_type"
            >
              <b-form-group
                label="Type of Plan"
                :class="errors.length > 0 ? 'is-invalid' : null"
                label-class="field-required"
              >
                <v-select
                  id="plan-type"
                  v-model="form.plan_type"
                  :options="suggestion.plan_type"
                  label="plan_type"
                  :reduce="option => option.plan_type"
                  placeholder="Select or search Plan Type"
                  :disabled="!canEdit"
                  @option:selected="onSelected('plan_type', $event, 'plan_type')"
                />
                <!-- vue-autosuggest
                  id="plan-type"
                  v-model="form.plan_type"
                  :suggestions="filteredSuggestion.plan_type"
                  :get-suggestion-value="(suggestion) => suggestion.item.plan_type"
                  :limit="10"
                  :input-props="{
                    id:'autosuggest__input',
                    class:{ 'form-control': true, 'is-invalid': errors.length > 0 },
                    placeholder:'Enter or select document plan type',
                    disabled: !canEdit,
                  }"
                  @selected="onSelected('plan_type', $event.item, 'plan_type')"
                  @input="onSuggestInput('plan_type', $event)"
                >
                  <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{ suggestion.item.plan_type }}</span>
                  </template>
                </vue-autosuggest -->
                <small
                  v-if="errors"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- plan owner -->
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Plan Owner"
              vid="form.plan_owner"
            >
              <b-form-group
                label="Plan Owner"
                :class="errors.length > 0 ? 'is-invalid' : null"
                label-class="field-required"
              >
                <v-select
                  id="plan-owner"
                  v-model="form.plan_owner"
                  :options="suggestion.plan_owner"
                  label="plan_owner"
                  :reduce="option => option.plan_owner"
                  placeholder="Select or search Plan Owner"
                  :disabled="true"
                  @option:selected="onSelected('plan_owner', $event, 'plan_owner')"
                />
                <!-- vue-autosuggest
                  id="plan-owner"
                  v-model="form.plan_owner"
                  :suggestions="filteredSuggestion.plan_owner"
                  :get-suggestion-value="(suggestion) => suggestion.item.plan_owner"
                  :limit="10"
                  :input-props="{id:'autosuggest__input',class:{ 'form-control': true, 'is-invalid': errors.length > 0 }, placeholder:'Enter or select document plan type', disabled: true}"
                  @selected="onSelected('plan_owner', $event.item, 'plan_owner')"
                  @input="onSuggestInput('plan_owner', $event)"
                >
                  <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{ suggestion.item.plan_owner }}</span>
                  </template>
                </vue-autosuggest -->
                <small
                  v-if="errors"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- plan no -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Plan No"
            >
              <vue-autosuggest
                id="plan-no"
                v-model="form.plan_no"
                :suggestions="filteredSuggestion.plan_no"
                :get-suggestion-value="(suggestion) => suggestion.item.plan_no"
                :limit="10"
                :input-props="{
                  id:'autosuggest__input',
                  class:{ 'form-control': true },
                  placeholder:'Enter or select document plan no',
                  disabled: !canEdit,
                }"
                @selected="onSelected('plan_no', $event.item.plan_no)"
                @input="onSuggestInput('plan_no', $event)"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.plan_no }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>

          <!-- contract no -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Contract No"
            >
              <vue-autosuggest
                id="contract-no"
                v-model="form.contract_no"
                :suggestions="filteredSuggestion.contract_no"
                :get-suggestion-value="(suggestion) => suggestion.item.contract_no"
                :limit="10"
                :input-props="{
                  id:'autosuggest__input',
                  class:{ 'form-control': true },
                  placeholder:'Enter or select document contract no',
                  disabled: !canEdit,
                }"
                @selected="onSelected('contract_no', $event.item.contract_no)"
                @input="onSuggestInput('contract_no', $event)"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.contract_no }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>

          <!-- state -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="State"
              vid="form.state_id"
            >
              <b-form-group
                label="State"
                :class="errors.length > 0 ? 'is-invalid' : null"
                label-class="field-required"
              >
                <v-select
                  id="state"
                  v-model="form.state_id"
                  label="state"
                  append-to-body
                  :options="states"
                  :reduce="state => state.id"
                  placeholder="Select State"
                  :state="errors.length > 0 ? false:null"
                  :disabled="!canEdit"
                  @option:selected="form.district_id = null"
                />
                <small
                  v-if="errors"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- district -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="District"
              vid="form.district_id"
            >
              <b-form-group
                label="District"
                :class="errors.length > 0 ? 'is-invalid' : null"
                label-class="field-required"
              >
                <v-select
                  id="district"
                  v-model="form.district_id"
                  :disabled="!canEdit || !form.state_id"
                  label="district"
                  append-to-body
                  :options="districts.filter(district => district.state_id == form.state_id)"
                  :reduce="district => district.id"
                  placeholder="Select District"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-if="errors"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- keywords -->
          <b-col cols="12">
            <b-form-group label="Keywords">
              <v-select
                id="keywords"
                v-model="form.keywords"
                label="name"
                append-to-body
                multiple
                taggable
                push-tags
                :options="keywords"
                placeholder="Select/enter keyword(s)"
                :disabled="!canEdit"
                @search="onKeywordSearch"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <!-- Header: Additional Data -->
        <div class="d-flex mt-3 mb-2">
          <feather-icon
            icon="DatabaseIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Additional Data
          </h4>
        </div>

        <!-- Fields: Additional Data -->
        <b-row class="mt-1">

          <!-- Field: Designer -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Designer"
              label-for="designer"
            >
              <b-form-input
                id="designer"
                v-model="form.designer"
                :disabled="!canEdit"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Year key in -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Year Key-In"
              label-for="year-key-in"
            >
              <b-form-input
                id="year-key-in"
                v-model="form.year_key_in"
                :disabled="!canEdit"
              />
            </b-form-group>
          </b-col>

          <!-- Plan Created Date -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Plan Created Date"
              label-for="plan-created-date"
            >
              <flat-pickr
                v-model="form.plan_created_date"
                :disabled="!canEdit"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d'}"
                placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Source -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Source"
              label-for="source"
            >
              <b-form-input
                id="source"
                v-model="form.source"
                disabled
              />
            </b-form-group>
          </b-col>

        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <div
          v-if="form.files && form.files.original"
          class="border border-secondary rounded p-1"
        >
          <b-media
            no-body
            class="mb-2"
          >
            <b-media-aside class="mr-1">
              <b-img
                :src="resolveFileIcon(get(form, 'files.original.mimetype'))"
                width="84"
              />
            </b-media-aside>
            <b-media-body class="my-auto">
              <b-card-text class="font-weight-bolder mb-0 text-break">
                {{ get(form, 'files.original.basename') }}
                <div class="font-small-3 mb-0 text-muted">
                  Size: {{ get(form, 'files.original.size', 0) | prettyBytes }}
                </div>
              </b-card-text>
            </b-media-body>
          </b-media>
          <div>
            <table
              class="table table-sm table-fixed"
              width="100%"
            >
              <tbody>
                <tr
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <td
                    width="20%"
                    class="text-small text-bold"
                  >
                    {{ item.key }}
                  </td>
                  <td class="text-small text-break">
                    {{ item.value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="canEdit"
          class="mt-3 border border-primary rounded bg-light-primary p-1"
        >
          <div class="d-flex mb-2">
            <feather-icon
              icon="AwardIcon"
              size="24"
            />
            <h4 class="mb-0 ml-50 my-auto">
              Approval
            </h4>
          </div>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Status">
                <v-select
                  id="status"
                  v-model="form.status"
                  :options="getStatusOptions"
                  label="label"
                  :reduce="option => option.value"
                  :disabled="!canEdit"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.latest_note"
              cols="12"
            >
              <b-form-group label="Note">
                <b-form-textarea
                  v-model="form.latest_note.note"
                  id="textarea-latest-note"
                  placeholder=""
                  rows="3"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="New Note">
                <b-form-textarea
                  v-model="form.note"
                  id="textarea-note"
                  placeholder=""
                  rows="3"
                  :disabled="!canEdit"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2 text-right">
      <b-col>
        <b-button
          variant="outline-secondary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="onCancel"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="!canEdit"
          @click="onSave"
        >
          Save Changes
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BLink, BImg, BTableLite,
  BMedia, BMediaAside, BMediaBody, BCardText, BFormTextarea,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, email } from '@validations'
import { VueAutosuggest } from 'vue-autosuggest'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { get, debounce } from 'lodash'
import router from '@/router'
import { getUserData } from '@/auth/utils'
import documentStoreModule from '../documentStoreModule'

export default {
  name: 'DocumentEditTabInformation',
  components: {
    VueAutosuggest,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BFormTextarea,
    BButton,
    BLink,
    BImg,
    BTableLite,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    documentData: {
      type: Object,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      statusOptions: [
        { label: 'Draft', value: 'draft', permission: null },
        { label: 'Review', value: 'review', permission: null },
        { label: 'Pending', value: 'pending', permission: 'publish' },
        { label: 'Approved', value: 'approved', permission: 'publish' },
        { label: 'Rejected', value: 'rejected', permission: 'publish' },
      ],
      keywords: [],
      form: {
        project_type: null,
        plan_type: null,
        plan_owner: null,
      },
      isSaving: false,
      suggestion: {
        main_title: [],
        sub_title: [],
        project_type: [],
        plan_type: [],
        plan_owner: [],
        plan_no: [],
        contract_no: [],
      },
      filteredSuggestion: {
        main_title: [],
        sub_title: [],
        project_type: [],
        plan_type: [],
        plan_owner: [],
        plan_no: [],
        contract_no: [],
      },
      selectedValue: {},
      states: [],
      districts: [],
    }
  },
  computed: {
    items() {
      return [
        {
          key: 'File Name', value: get(this.form, 'files.original.basename'),
        },
        {
          key: 'Size', value: get(this.form, 'files.original.size', 0),
        },
        {
          key: 'Type', value: get(this.form, 'files.original.mimetype'),
        },
        {
          key: 'Path', value: get(this.form, 'files.original.url'),
        },
        {
          key: 'Upload Date', value: this.form.created_at,
        },
        {
          key: 'Uploaded By', value: this.form.user.name,
        },
      ]
    },
    getStatusOptions() {
      const user = getUserData()
      if (user.role === 'administrator') {
        return this.statusOptions
      }
      return this.statusOptions.filter(s => s.permission == null)
    },
  },
  watch: {
    documentData: {
      handler() {
        this.form = {
          ...this.form,
          ...this.documentData,
        }
        this.loadForm()
      },
      deep: true,
    },
  },
  created() {
    // Register module
    if (!store.hasModule('app-document')) store.registerModule('app-document', documentStoreModule)
  },
  mounted() {
    store.cache
      .dispatch('app-document/fetchDocumentMeta', {
        field: 'main_title;sub_title;project_type_id;plan_type_id;plan_owner_id;state_id;district_id;plan_no;contract_no',
      })
      .then(response => {
        this.suggestion.main_title = response.data.payload.main_title
        this.suggestion.sub_title = response.data.payload.sub_title

        this.suggestion.project_type = get(response, 'data.payload.project_type_id')
        this.suggestion.plan_type = get(response, 'data.payload.plan_type_id')
        this.suggestion.plan_owner = get(response, 'data.payload.plan_owner_id')
        this.suggestion.plan_no = get(response, 'data.payload.plan_no')
        this.suggestion.contract_no = get(response, 'data.payload.contract_no')
        this.states = get(response, 'data.payload.state_id')
        this.districts = get(response, 'data.payload.district_id')

        this.loadForm()
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching documents' meta",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    get,
    loadForm() {
      this.$nextTick(() => {
        // project type
        this.selectedValue.project_type = this.suggestion.project_type.find(p => p.id === this.form.project_type_id)
        this.form.project_type = get(this.selectedValue, 'project_type.project_type')
        // plan type
        this.selectedValue.plan_type = this.suggestion.plan_type.find(p => p.id === this.form.plan_type_id)
        this.form.plan_type = get(this.selectedValue, 'plan_type.plan_type')
        // plan owner
        this.selectedValue.plan_owner = this.suggestion.plan_owner.find(p => p.id === this.form.plan_owner_id)
        this.form.plan_owner = get(this.selectedValue, 'plan_owner.plan_owner')
      })
    },
    onSuggestInput(key, text) {
      this.form[key] = text

      this.selectedValue[key] = {}
      this.selectedValue[key].id = null
      this.selectedValue[key][key] = text

      if (text === '' || text === undefined) {
        return
      }

      const filtered = this.suggestion[key].filter(data => data[key].toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, 10)
      this.$nextTick(() => {
        this.filteredSuggestion[key] = [{ data: filtered }]
      })
    },
    onSelected(field, value, key = null) {
      if (key) {
        this.selectedValue[field] = value
      }
      this.form[field] = key ? value[key] : value
    },
    onKeywordSearch: debounce(
      function (search, loading) {
        if (!search) return
        loading(true)
        store.dispatch('app-document/searchKeywordTags', search)
          .then(response => {
            console.log(response.data.payload)
            this.keywords = response.data.payload
          })
          .catch(() => {
            this.keywords = []
          })
          .finally(() => {
            loading(false)
          })
      },
      400,
    ),
    onSave() {
      this.$refs.documentFormTabInfo.validate().then(success => {
        if (success) {
          const payload = { ...this.form }
          // replace with selectedValue
          Object.keys(this.selectedValue).forEach(key => {
            // main_title, sub_title, plan_no, contract_no field is currently string
            if (!['main_title', 'sub_title', 'plan_no', 'contract_no'].includes(key)) {
              delete payload[key]
              payload[key] = this.selectedValue[key]
            }
          })
          store.dispatch('app-document/updateDocument', { hashid: this.form.hashid, payload })
            .then(() => {
              // redirect
              router.push({ name: 'document-list' })

              // toast
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Document saved!',
                  icon: 'SaveIcon',
                  variant: 'success',
                },
              })
            })
            .catch(({ response }) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error updating document',
                  text: response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating document',
            text: error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    onCancel() {
      router.push({ name: 'document-list' })
    },
    resolveFileIcon(mimetype) {
      switch (mimetype) {
        case 'application/pdf':
          return '/images/file-types/pdf.png'
        case 'image/vnd.dwg':
          return '/images/file-types/dwg.png'
        default:
          return '/images/file-types/document.png'
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.field-required:after {
  content: ' *';
  color: $danger;
}
</style>

<style lang="scss" scoped>
.v-select {
  background-color: #fff;
}

.v-select:disabled {
  background-color: #efefef;
}

.hidden-header {
  display: none;
}
.text-small {
  font-size: 85%;
}
.text-bold {
  font-weight: bold;
}
</style>
