import {ref, watch, computed, nextTick} from '@vue/composition-api'
import store from '@/store'
import { httpGetFile } from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from "@/router";

export default function useDocumentsList() {
  // Use toast
  const toast = useToast()

  const refDocumentListTable = ref(null)

  const statusOptions = [
    'draft',
    'review',
    'pending',
    'approved',
    'rejected',
  ]

  // Table Handlers
  const tableColumns = [
    {
      key: 'id', label: '#', thClass: 'text-center', tdClass: 'text-center', sortable: true,
    },
    { key: 'main_title', sortable: true },
    { key: 'sub_title', sortable: true },
    { key: 'plan_types|plan_types.name', label: 'Plan Type', sortable: true },
    { key: 'plan_owners|plan_owners.name', label: 'Plan Owner', sortable: true },
    { key: 'states|states.name', label: 'State', sortable: true },
    { key: 'districts|districts.name', label: 'District', sortable: true },
    { key: 'project_types|project_types.name', label: 'Project Type', sortable: true },
    { key: 'actions', thClass: 'text-center', tdClass: 'text-center' },
  ]
  const perPage = ref(25)
  const totalDocuments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)
  const searchAdvance = ref({
    main_title: null,
    sub_title: null,
    'projectType.name': null,
    'keywords.name': null,
    plan_no: null,
    contract_no: null,
    state: null,
    district: null,
    'planOwner.name': null,
  })
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref([])
  const useAdvanceSearch = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refDocumentListTable.value ? refDocumentListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDocuments.value,
    }
  })

  const refetchData = () => {
    refDocumentListTable.value.refresh()
  }

  const getFetchPayload = (isExport = 0) => {
    // quick search or advance search
    let search = ''
    if (useAdvanceSearch.value) {
      search = Object.entries(searchAdvance.value || {})
        .filter(([key, value]) => ((Array.isArray(value))
          ? value.length > 0
          : value))
        .map(([key, value]) => (Array.isArray(value)
          ? `${key}:${value.join(',')}`
          : `${key}:${value}`))
      search = search.join(';')
    } else {
      search = searchQuery.value
    }

    return {
      params: {
        with: 'state:id,code,name;district:id,code,name;planOwner:id,name;projectType:id,name;planType:id,name;projectTypeDetail:id,name;keywords;user:id,name',
        search,
        search_join: useAdvanceSearch.value ? 'and' : 'or',
        per_page: perPage.value,
        page: currentPage.value,
        order_by: sortBy.value,
        sort_dir: isSortDirDesc.value ? 'desc' : 'asc',
        statuses: statusFilter.value,
        export: isExport ? 1 : 0,
      },
      config: isExport ? { responseType: 'blob' } : {},
    }
  }

  const fetchDocuments = (ctx, callback) => {
    store
      .dispatch('app-document/fetchDocuments', getFetchPayload())
      .then(response => {
        callback(response.data.payload.data)
        totalDocuments.value = response.data.payload.total
        // goto page
        // nextTick(() => {
        //   currentPage.value = parseInt(router.app.$route.query.page) || 1
        // })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching documents' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveDocumentStatusVariantAndIcon = status => {
    if (status === 'draft') return { variant: 'secondary', icon: 'EditIcon' }
    if (status === 'review') return { variant: 'warning', icon: 'ListIcon' }
    if (status === 'pending') return { variant: 'warning', icon: 'ClockIcon' }
    if (status === 'approved') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'rejected') return { variant: 'danger', icon: 'XCircleIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  watch([currentPage, perPage, searchQuery, statusFilter, useAdvanceSearch], () => {
    refetchData()
  }, { deep: true })

  return {
    getFetchPayload,
    fetchDocuments,
    statusOptions,
    tableColumns,
    perPage,
    currentPage,
    totalDocuments,
    dataMeta,
    perPageOptions,
    searchQuery,
    searchAdvance,
    sortBy,
    isSortDirDesc,
    refDocumentListTable,

    statusFilter,
    useAdvanceSearch,

    resolveDocumentStatusVariantAndIcon,
    refetchData,
  }
}
